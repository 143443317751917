import { defineStore } from 'pinia'
import { store } from '@admin/store'
// import { useSystemInfo } from '@admin/layouts/default/systemMenu/useSystemInfo.ts'

interface SystemMenuState {
  // 所有的系统菜单列表
  allSystemList: any[]
  // 所有的菜单列表
  allMenuList: any[]
  // 收藏列表
  collectionList: any[]
  // 当前系统id信息
  curSystem: string | number | null
  // 当前系统菜单列表
  curSystemRouteList: any[]
}

export const useSystemMenuStore = defineStore({
  id: 'app-system-menu',
  persist: {
    enabled: true, //开启数据持久化
    strategies: [
      {
        key: 'app-system-menu', //给一个要保存的名称
        storage: sessionStorage, //sessionStorage / localStorage 存储方式
        paths: ['allSystemList', 'collectionList', 'curSystem'],
      },
    ],
  },
  state: (): SystemMenuState => ({
    curSystem: null,
    curSystemRouteList: [],
    allSystemList: [],
    collectionList: [], // 收藏列表
    allMenuList: [],
  }),
  getters: {},
  actions: {
    setCurSystem(val: string | number) {
      this.curSystem = val
    },

    setCurSystemRouteList(list: any[]) {
      this.curSystemRouteList = list
    },

    setAllSystemList(list: any[]) {
      this.allSystemList = list
    },

    setCollectionList(list: any) {
      this.collectionList = list
    },

    updatedCollectionList(item: any, type = 'add') {
      if (type === 'add') {
        this.collectionList.push(item)
      } else {
        this.collectionList = this.collectionList.filter((v) => v.id !== item.id)
      }
    },

    setAllMenuList(list: any) {
      this.allMenuList = list
    },

    resetState(): void {
      this.$reset()
    },
  },
})

// Need to be used outside the setup
// 需要在设置之外使用
export function useSystemMenuStoreWithOut() {
  return useSystemMenuStore(store)
}
