import { useSystemMenuStore } from '@admin/store/modules/systemMenu'

/**
 * 扁平化菜单树
 * @param menuTree 菜单树
 * @returns []
 */
export const flattenMenuTree = (menuTree: any) => {
  const flattened = [] as any
  for (const item of menuTree) {
    flattened.push(item)
    if (item.children && item.children.length > 0) {
      flattened.push(...flattenMenuTree(item.children))
    }
  }
  return flattened
}

/**
 * 寻找出所有的父级menuId
 * @param menu 菜单项
 * @returns []
 */
export const findMenuParentIds = (menu: any) => {
  const systemMenuStore = useSystemMenuStore()
  const ids = [] as any
  if (menu) {
    ids.unshift(menu.id)
    if (menu.parent_id) {
      const paren = systemMenuStore.allMenuList.find((v) => v.id === menu.parent_id)
      ids.unshift(...findMenuParentIds(paren))
    }
  }
  return ids
}

/**
 * 寻找所有的收藏末菜单
 * @param systemList
 */
export const findAllCollectionMenu = () => {
  const treeFn = (tree) => {
    return tree.reduce((res, item) => {
      const { children, ...i } = item
      return res.concat(
        i && i.is_keep && !children ? i : [],
        children && children.length ? treeFn(children) : [],
      )
    }, [])
  }
  const systemMenuStore = useSystemMenuStore()
  const list = treeFn(systemMenuStore.allSystemList)
  return list
}
/**
 * 过滤树内容
 * @param tree
 * @param func
 */
export const treeFilter = (tree, func) => {
  return tree
    .map((node) => ({ ...node }))
    .filter((node) => {
      node.children = node.children && treeFilter(node.children, func)
      return func(node) || (node.children && node.children.length)
    })
}
