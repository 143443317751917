import { defineStore } from 'pinia'
import { store } from '@admin/store'

import { queryMenuList } from '@admin/api/common/common'
import { useSystemInfo } from '@admin/layouts/default/systemMenu/useSystemInfo'
// import { newMenu } from '@admin/newMenu'

export const usePermissionStore = defineStore({
  id: 'app-permission',
  state: (): any => ({
    menuList: [],
  }),

  actions: {
    async getMenuList() {
      const { init: systemInit } = useSystemInfo()
      try {
        const res = await queryMenuList()
        const menus = res.data.menus
        // 权限判断方法要使用
        localStorage.setItem('userMenuGet', JSON.stringify(res))
        systemInit(menus)
      } catch (error) {
        systemInit([])
      }
    },

    // 设置菜单内容
    setMenuList(list) {
      this.menuList = list
    },

    resetState(): void {
      this.$reset()
    },
  },
})

// Need to be used outside the setup
// 需要在设置之外使用
export function usePermissionStoreWithOut() {
  return usePermissionStore(store)
}
