// import { userMenuGet } from '@admin/api/common/common'
import { adminRequest } from '@admin/utils/http/axios'
import { MenuKeepParams } from './model/systemMenu'

enum Api {
  postMenuKeep = '/admin/menu/keep', // 收藏菜单
  postMenuUnKeep = '/admin/menu/unkeep', // 取消收藏菜单
  getMenuKeepList = '/admin/menu/keep-list', // 收藏的菜单列表
}

/**
 * 菜单收藏
 * @returns getUploadTempKeyModel
 */
export const postMenuKeep = (data: MenuKeepParams) => {
  return adminRequest.post({ url: Api.postMenuKeep, data })
}

export const postMenuUnKeep = (data: MenuKeepParams) => {
  return adminRequest.post({ url: Api.postMenuUnKeep, data })
}

export const getMenuKeepList = () => {
  return adminRequest.get({ url: Api.getMenuKeepList })
}
