import { reactive, unref, Ref } from 'vue'
import type { MenuState } from './types'
const menuState = reactive<MenuState>({
  defaultSelectedKeys: [],
  openKeys: [],
  selectedKeys: [],
})
export const useMenuState = () => {
  const setSelectedKeys = (key: Ref<string> | string) => {
    menuState.selectedKeys = [unref(key)]
  }
  const setSelectedKeysArr = (arr: any) => {
    menuState.selectedKeys = arr
  }
  const setMenuOpenKeys = (key: Ref<string> | string) => {
    const value = unref(key)
    menuState.openKeys.push(value)
    menuState.openKeys.push(`submenu-${value}`)
  }
  const handleOpenChange = (openKeys: string[]) => {
    menuState.openKeys = openKeys
  }
  const resetOpenKeys = () => {
    menuState.openKeys = []
  }
  const resetSelectKeys = () => {
    menuState.selectedKeys = []
  }
  const resetKeys = () => {
    menuState.openKeys = []
    menuState.selectedKeys = []
  }
  return {
    menuState,
    setSelectedKeys,
    setSelectedKeysArr,
    setMenuOpenKeys,
    resetOpenKeys,
    resetSelectKeys,
    handleOpenChange,
    resetKeys,
  }
}
