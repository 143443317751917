/**
 * Multi-language related operations
 */
import type { LocaleType } from 'framework/types/config'

import { i18n } from './setupI18n'
import { unref, computed } from 'vue'
import { loadLocalePool, setHtmlPageLang, lang } from './helper'
import { LOCALE_KEY, LOCALE_TEXT } from 'framework/enums/cacheEnum'
import { localeSetting } from 'framework/settings/localeSetting'
import type { LocaleSetting } from 'framework/types/config'

interface LangModule {
  message: Recordable
  dateLocale: Recordable
  dateLocaleName: string
}

function setI18nLanguage(locale: LocaleType) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    ;(i18n.global.locale as any).value = locale
  }
  const lsLocaleSetting = ((localStorage.getItem(LOCALE_KEY) &&
    JSON.parse(localStorage.getItem(LOCALE_KEY))) ||
    localeSetting) as LocaleSetting

  localStorage.setItem(LOCALE_KEY, JSON.stringify({ ...lsLocaleSetting, locale }))
  localStorage.setItem(LOCALE_TEXT, locale)

  setHtmlPageLang(locale)
}

let langModule = null

export function useLocale() {
  // const lsLocaleSetting = ((localStorage.getItem(LOCALE_KEY) &&
  //   JSON.parse(localStorage.getItem(LOCALE_KEY))) ||
  //   localeSetting) as LocaleSetting
  const getLocale = computed((): any => {
    return lang.value
  })
  const getShowLocalePicker = true
  const getAntdLocale = computed((): any => {
    return i18n?.global.getLocaleMessage(unref(getLocale))?.antdLocale ?? {}
  })

  // Switching the language will change the locale of useI18n
  // And submit to configuration modification
  async function changeLocale(locale: LocaleType, newMessage) {
    const globalI18n = i18n.global
    // const currentLocale = unref(globalI18n.locale)
    // if (currentLocale === locale) {
    //   return locale
    // }

    // if (loadLocalePool.includes(locale)) {
    //   setI18nLanguage(locale)
    //   return locale
    // }

    // if (!langModule) {
    //   console.log(888)

    langModule = ((await import(`./lang/${locale}.ts`)) as any).default as LangModule
    // }

    // if (!langModule) return

    const { message } = langModule

    const contactMessage = {
      ...message,
      ...newMessage,
    }

    setI18nLanguage(locale)

    globalI18n.setLocaleMessage(locale, contactMessage)
    loadLocalePool.push(locale)

    // return locale
  }

  return {
    getLocale,
    getShowLocalePicker,
    changeLocale,
    getAntdLocale,
  }
}
